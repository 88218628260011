<template>
  <div>
    <b-row class="justify-content-center my-2"><h8>Program ataması için "Tüm programlar" listesinden ilgili programı bulunuz ve sürükle bırak yaparak ilgili alana bırakınız. </h8></b-row>
    <b-row class="justify-content-center my-2"><h8>Ataması yapılmış bir programı kaldırmak için ilgili programı sürükle bırak yaparak "Tüm programlar" listesine bırakınız. </h8></b-row>

    <b-row>
      <!-- basic select -->
      <b-col md="4" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Program ara.." />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Temizle </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <!-- people group 1 -->
      <b-col md="4" v-if="!isLoading">
        <h6 class="text-primary font-weight-bold mb-2">Tüm Programlar</h6>

        <!-- draggable -->
        <!-- {{ sortOptions }} -->
        <draggable :list="sortOptions" tag="ul" group="people"   @change="log('programList',$event)"  class="list-group list-group-flush cursor-move">
          <b-list-group-item v-for="(listItem, index) in sortOptions" :key="index" tag="li">
            <div class="d-flex">
              <b-avatar :text="listItem.name.slice(0, 2)" />
              <div class="ml-25">
                <div class="mb-0 font-weight-bold">
                  {{ listItem.name }}
                </div>
                <!-- <small>{{ listItem.email }}</small> -->
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>

      <!-- people group 2 -->
      <b-col md="4" v-if="!isLoading" class="mt-sm-2 mt-md-0">
        <h6 class="text-success font-weight-bold mb-2">Departmana Atanan Verimli Programlar</h6>

        <!-- draggable -->
        <draggable :list="list2" tag="ul" @change="log('productive',$event)"    group="people" class="list-group list-group-flush cursor-move">
          <b-list-group-item v-for="(listItem, index) in list2" :key="index" tag="li">
            <div class="d-flex">
              <b-avatar :text="listItem.name.slice(0, 2)" />
              <div class="ml-25">
                <div class="mb-0 font-weight-bold">
                  {{ listItem.name }}
                </div>
               
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
      <!-- people group 3 -->
      <b-col md="4" v-if="!isLoading" class="mt-sm-2 mt-md-0">
        <h6 class="text-danger font-weight-bold mb-2">Departmana Atanan Verimsiz Programlar</h6>

        <!-- draggable -->
        <draggable :list="list3" tag="ul" @change="log('unproductive',$event)"   group="people" class="list-group list-group-flush cursor-move">
          <b-list-group-item v-for="(listItem, index) in list3" :key="index" tag="li">
            <div class="d-flex">
              <b-avatar :text="listItem.name.slice(0, 2)" />
              <div class="ml-25">
                <div class="mb-0 font-weight-bold">
                  {{ listItem.name }}
                </div>
               
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
   
    </b-row>
  </div>
</template>

<script>
import { BListGroupItem, BAvatar, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton } from "bootstrap-vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import { Api } from "@/helpers/Api/apiConfig";
import vSelect from "vue-select";

export default {
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    Prism,
    draggable,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      const result = this.list1.filter((f) => f.name.toLowerCase().includes(this.filter.toLowerCase()));
      console.log('sdddd',result);
      result.map((f) => ({ name: f.name, value: f.programId,departmentProgId:f.departmentProgramId}));
      return result
    },
  },
  data() {
    return {
      filter: "",
      filterOn: [],
      isLoading: false,
      selected: null,
      list1: [
     
      ],
      list2: [
      
      ],
      list3: [
      
      ],
    };
  },
  mounted() {
    //  this.getAllPrograms()
     this.getDepartmentPrograms()
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(name, evt) {
      console.log('event',evt);
  switch (name) {
    case 'programList':
      if (evt.added) {
        alert('program silindi');
        let departmentProgId=evt.added.element.departmentProgId?evt.added.element.departmentProgId:false
console.log('sss',departmentProgId);
        if(departmentProgId){
  this.deleteDepartmentProgram('PUT',departmentProgId)

}else{
  alert('İşlem başarısız!')
}
      }
      break;
    case 'productive':
      if (evt.added) {
        console.log('list1 eklendi',evt.added.element);
        let programId=evt.added.element.value
        let productiveLevel = 1
        let departmentProgId=evt.added.element.departmentProgId?evt.added.element.departmentProgId:false
        // if(!departmentProgId){
        //   this.addProgramToProductivity('POST',programId,productiveLevel)

        // }else{
          this.updateDepartmentProgram('PUT',departmentProgId,productiveLevel)
        //}
    
      }
      break;
    case 'unproductive':
      if (evt.added) {
        alert('list2 ye eklendi');
        let programId=evt.added.element.value
        let productiveLevel = 0
        let departmentProgId=evt.added.element.departmentProgId?evt.added.element.departmentProgId:false
        // if(!departmentProgId){
        //   this.addProgramToProductivity('POST',programId,productiveLevel)

        // }else{
          this.updateDepartmentProgram('PUT',departmentProgId,productiveLevel)
        //}
      }
      break;
    default:
      break;
  }
 // console.log(name, evt);
},
    // log: function(evt) {
    //   window.console.log(evt);
    // },
    // start({ originalEvent }) {
    //   this.controlOnStart = originalEvent.ctrlKey;
    //   console.log('ss',originalEvent);
    // },
    async addProgramToProductivity(registerType,programId,productiveLevel){
      this.isLoading = true;
      let departmentId = this.$route.params.id
      await Api(registerType, "DepartmentProgram",{
        departmentId:departmentId,
        programId:programId,
        productiveLevel:productiveLevel,
       // isAdmin:true
      }).then((res) => {
        if( res.data.message == "OK" ){
          alert('Ekleme işlemi Başarılı')
        }else{
          alert('Ekleme işlemi Başarısız! Lütfen tekrar deneyiniz!')

        }
       
        });
      
        this.isLoading = false;
     
    },
    async updateDepartmentProgram(registerType,departmentProgId,productiveLevel){
      this.isLoading = true;
      //let departmentId = Number(this.$route.params.id)
      await Api(registerType, "DepartmentProgram/"+departmentProgId,{
      
        productiveLevel:productiveLevel,
        
      }).then((res) => {
        if( res.data.message == "OK" ){
          alert('Ekleme işlemi Başarılı')
        }else{
          alert('Ekleme işlemi Başarısız! Lütfen tekrar deneyiniz!')

        }
       
        });
      
        this.isLoading = false;
     
    },
    async deleteDepartmentProgram(registerType,deparmentProgId){
      this.isLoading = true;
     
      // let departmentId = Number(this.$route.params.id)
      await Api(registerType, "DepartmentProgram/"+deparmentProgId,{
      
      productiveLevel:-1,
      
    }).then((res) => {
        if( res.data.message == "OK" ){
          alert('Program listeden başarılı bir şekilde kaldırıldı!')
        }else{
          alert('Program kaldırma işlemi Başarısız! Lütfen tekrar deneyiniz!')

        }
       
        });
      
        this.isLoading = false;
     
    },
   

    // async getAllPrograms() {
    //   this.isLoading = true;
    //   await Api("GET", "Program").then((res) => {
    //     this.list1 = res.data.data.items;
    //     // this.departmentList = this.departments.map((item) => {
    //     //   return {
    //     //     text: item.name,
    //     //     value: item.id,
    //     //     parent: item.parent,

    //     //   };
    //     // });
    //     // this.departmentList.unshift({
    //     //   text: 'Tüm Departmanlar',
    //     //   value: null,

    //     // })
    //     // this.$emit('departmentList',this.departmentList)

    //     // this.totalRows = this.departmentList.length
    //     // console.log( 'userrdepartments',this.departments);
    //     this.isLoading = false;
    //   });
    // },
  async getDepartmentPrograms(){
      this.isLoading = true;
      let departmentId = this.$route.params.id
      await Api("GET", "DepartmentProgram?departmentId=" + departmentId).then((res) => {
        let allList = res.data.data.items;
        console.log('allList',allList);
        this.list1=[]
        this.list2=[]
        this.list3=[]
       allList.map((item) => {
          item.productiveLevel == 1?this.list2.push({name:item.program.name,value:item.program.programId,departmentProgId:item.departmentProgramId}):item.productiveLevel == 0?this.list3.push({name:item.program.name,value:item.program.programId,departmentProgId:item.departmentProgramId}):this.list1.push({name:item.program.name,value:item.program.programId,departmentProgId:item.departmentProgramId})
          // return {
          //   text: item.name,
          //   value: item.id,
          //   parent: item.parent,

          // };
        });
        // this.departmentList.unshift({
        //   text: 'Tüm Departmanlar',
        //   value: null,

        // })
        // this.$emit('departmentList',this.departmentList)

        // this.totalRows = this.departmentList.length
        // console.log( 'userrdepartments',this.departments);
        this.isLoading = false;
        console.log('list1',this.list1);
        console.log('list1',this.list2);

      });
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.list-group {
  height: 100vh;
  overflow-y: scroll;
}
.dark-layout .list-group .list-group-item:not([class*=list-group-item-]):hover, [dir] .dark-layout .list-group .list-group-item:not([class*=list-group-item-]):focus, [dir] .dark-layout .list-group .list-group-item.list-group-item-action:hover, [dir] .dark-layout .list-group .list-group-item.list-group-item-action:focus {
    background-color: #7367f0 !important;
}
</style>
